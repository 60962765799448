import { lazy } from "react";

export const TemplateTypes = {
  NONE: "none", //no layout at all
  DEFAULT: "default", //default layout with header and footer
  CONTENT: "content", // they type of layout for regular content
  SPLASH: "splash", // they type of layout for regular content
};

let anonymousRoutes = [
  {
    path: "/",
    component: lazy(() => import("../components/Home")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["anon", "user", "admin"],
  },
  {
    path: "/home",
    component: lazy(() => import("../components/HomeOg")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["anon", "user", "admin"],
  },
  {
    path: "/privacy-policy",
    component: lazy(() => import("../components/content/PrivacyPolicy")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["anon", "user", "admin"],
  },

  {
    path: "/careers",
    component: lazy(() => import("../components/content/Careers")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["anon", "user", "admin"],
  },

  {
    path: "/investors",
    component: lazy(() => import("../components/content/Investors")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["anon", "user", "admin"],
  },

  {
    path: "/register",
    component: lazy(() => import("../components/authentication/SignUp")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["anon"],
  },
  {
    path: "/register/confirm",
    component: lazy(() =>
      import("../components/authentication/ForgotPassword")
    ),
    exact: true,
    temmplate: TemplateTypes.NONE,
    roles: ["anon"],
  },
  {
    path: "/signin",
    component: lazy(() => import("../components/authentication/SignIn")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["anon"],
  },
  {
    path: "/member/:handle",
    component: lazy(() => import("../components/members/MemberProfile")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["anon", "user", "admin"],
  },
  {
    path: "/maphia/:name",
    component: lazy(() =>
      import("../components/maphias/profileView/MaphiaProfile")
    ),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["anon", "user", "admin"],
  },
  {
    path: "/maphia/:name/join",
    component: lazy(() => import("../components/maphias/JoinMaphia")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/search",
    component: lazy(() =>
      import("../components/maphias/searchView/SearchView")
    ),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["anon", "user", "admin"],
  },
  {
    path: "/explore",
    component: lazy(() => import("../components/commonUI/PageStarter")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["anon", "user", "admin"],
  },
  {
    path: "/forgotPassword",
    component: lazy(() =>
      import("../components/authentication/ForgotPassword")
    ),
    exact: true,
    roles: ["anon"],
  },
  {
    path: "/comingsoon",
    component: lazy(() => import("../components/ComingSoon")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["anon", "user", "admin"],
  },
  {
    path: "/cs/:code",
    component: lazy(() => import("../components/ComingSoon")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["anon", "user", "admin"],
  },
];

let userRoutes = [
  {
    path: "/invite",
    component: lazy(() => import("../components/commonUI/PageStarter")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/chat",
    component: lazy(() => import("../components/commonUI/PageStarter")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/maphias",
    component: lazy(() => import("../components/maphias/listView/MaphiasView")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/maphias/new",
    component: lazy(() => import("../components/maphias/AddEditMaphia")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/inner-circles",
    component: lazy(() => import("../components/maphias/listView/MaphiasView")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/inner-circles/new",
    component: lazy(() => import("../components/maphias/AddEditMaphia")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/user/profile",
    component: lazy(() => import("../components/users/Profile")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/setup/username",
    component: lazy(() => import("../components/users/HandleForm")),
    exact: true,
    template: TemplateTypes.NONE,
    roles: ["user"],
  },
  {
    path: "/user/profile/:id",
    component: lazy(() => import("../components/users/Profile")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/user/settings/security",
    component: lazy(() => import("../components/users/Security")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/user/change-password",
    component: lazy(() =>
      import("../components/authentication/ChangePassword")
    ),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
  {
    path: "/user/settings/verify-identity",
    component: lazy(() =>
      import("../components/authentication/VerifyIdentityView")
    ),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["user", "admin"],
  },
];

let adminRoutes = [
  {
    path: "/admin/maphias/dashboard",
    component: lazy(() =>
      import("../components/admin/MaphiaManagementDashboard")
    ),
    exact: true,
    template: TemplateTypes.NonAuthLayout,
    roles: ["admin"],
  },
  {
    path: "/admin/users/search",
    component: lazy(() => import("../components/admin/AdminSearchView")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["admin"],
  },
  {
    path: "/admin",
    component: lazy(() =>
      import("../components/layouts/horizontallayout/AdminTools.jsx")
    ),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["admin"],
  },
  {
    path: "/user/blocked",
    component: lazy(() => import("../components/users/BlockedUsers")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["admin"],
  },
  {
    path: "/admin/users/flagged",
    component: lazy(() => import("../components/admin/FlaggedUsers")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["admin"],
  },
  {
    path: "/admin/users/add",
    component: lazy(() => import("../components/admin/AddUsers")),
    exact: true,
    template: TemplateTypes.DEFAULT,
    roles: ["admin"],
  },
];

const routes = {
  anonymous: anonymousRoutes,
  authenticated: [...anonymousRoutes, ...userRoutes, ...adminRoutes],
};

export default routes;
