import {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
  axiosClient,
} from "./serviceHelpers";

const api = `${API_HOST_PREFIX}/users`;

/**
 *
 * @param {{name: string,
 * email: string,
 * password: string,
 * passwordConfirm: string}} payload
 */
export const register = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/register`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * email: string,
 * password: string}} payload
 */
export const login = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/login`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

export const getCurrent = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/current`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

export const logout = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/signout`,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * email: string,
 * password: string}} payload
 */
export const changePassword = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    url: `${api}/change-password`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {email} email
 */
export const requestPasswordReset = async (email) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/forgot-password`,
    data: email,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * password: string
 * passwordConfirm: string,}} payload
 */
export const resetPassword = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    url: `${api}/reset-password`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * password: string
 * passwordConfirm: string,}} payload
 */
export const resetPasswordAnon = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    url: `${api}/reset-password`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 * @param {string} token
 */
export const confirmEmail = async (token, invitationToken) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/registration/confirm?token=${token}${
      invitationToken ? `&invitationToken=${invitationToken}` : ""
    }`,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 * @param {string} token
 */
export const confirmExternalRegistration = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    url: `${api}/registration/external/confirm`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 * @param {{
 * userId: string,
 * issuedBy: string,
 * providerId: string,
 * url: string,
 * externalId: string,
 * }} payload
 */
export const reportUser = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/report`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * phoneNumber: string,}} payload
 */
export const requestVerification = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/verification`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};
/**
 *
 * @param {{
 * phoneNumber: string,
 * verificationCode: string}} payload
 */
export const confirmIdentity = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/verification/confirm`,
    data: payload,
  };
  try {
    const response = await axiosClient(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};
