export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getTimeDifference = (timeElapsed) => {
  if (timeElapsed < 0) {
    return "The date is in the future";
  }

  const seconds = timeElapsed % 60;
  const minutes = Math.floor(timeElapsed / 60) % 60;
  const hours = Math.floor(timeElapsed / 3600) % 24;
  const days = Math.floor(timeElapsed / (3600 * 24));

  if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"}`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"}`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  } else {
    return `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
  }
};
