import { AppContextProvider } from "./contexts/GlobalAppContext";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import sentryConfig from "constants/sentryConfig";
import { getUserTimeZone } from "utils/dateTimeHelper";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: sentryConfig.environment,
    // Tracing
    tracesSampleRate: sentryConfig.tracesSampleRate,
    tracePropagationTargets: sentryConfig.tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
    replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        blockAllMedia: sentryConfig.replay.blockAllMedia,
      }),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: sentryConfig.feedback.colorScheme,
        isNameRequired: sentryConfig.feedback.isNameRequired,
        tags: {
          // Additional tags to be sent with every transaction
          timeZone: getUserTimeZone(),
        },
      }),
    ],
    beforeSend(event, hint) {
      if (
        event.level &&
        !sentryConfig.enabledEventLevels.includes(event.level)
      ) {
        return null;
      }

      if (event) {
        const userTimeZone = getUserTimeZone();
        event.tags = {
          ...event.tags,
          timeZone: userTimeZone,
        };
      }

      if (
        hint &&
        hint.originalException &&
        hint.originalException.isAxiosError
      ) {
        if (
          hint.originalException.response &&
          hint.originalException.response.data
        ) {
          let contexts = {
            ...event.contexts,
          };
          contexts.errorResponse = {
            data: hint.originalException.response.data,
          };
          event.contexts = contexts;
        }
      }

      return event;
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </Router>
);
