import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";
import { useAppContext } from "contexts/GlobalAppContext";
import UserAvatar from "components/commonUI/UserAvatar";
import { DOMAIN } from "@services/serviceHelpers";
import QrScanner from "react-qr-scanner";

const QRCodeModal = ({ isOpen, toggle }) => {
  const { user } = useAppContext();
  const avatarUrl = user.current.avatar ? user.current.avatar.url : "";
  const profileUrl = `${DOMAIN}/member/${user.current.handle}`;

  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [isCamaraOpen, setIsCamaraOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const qrCodeRef = useRef();

  const onCopy = () => {
    navigator.clipboard.writeText(profileUrl);
    setIsLinkCopied(true);
    window.setTimeout(() => {
      setIsLinkCopied(false);
    }, 1000);
  };

  const downloadQrCode = () => {
    const svgElement = qrCodeRef.current.querySelector("svg");
    if (!svgElement) return;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const svgString = new XMLSerializer().serializeToString(svgElement);
    const img = new Image();

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;

      context.drawImage(img, 0, 0);

      const a = document.createElement("a");
      a.download = "qr-code.png";
      a.href = canvas.toDataURL("image/png");
      a.click();
    };

    img.src = "data:image/svg+xml;base64," + btoa(svgString);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === "2") {
      setIsCamaraOpen(true);
    } else {
      setIsCamaraOpen(false);
    }
  };

  const handleModalToggle = () => {
    toggle();
    setIsCamaraOpen(false);
  };

  const checkIfMobileOrTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /android|iphone|ipod|ipad|mobile|windows phone/i.test(
      userAgent
    );
    setIsMobile(isMobile);
  };

  const handleScan = (data) => {
    if (data) {
      const newUrl = data.text.startsWith("http")
        ? data.text
        : `https://${data.text}`;
      window.location.href = newUrl;
    }
  };

  useEffect(() => {
    checkIfMobileOrTablet();
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={handleModalToggle} centered>
      {user.current && (
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => toggleTab("1")}
                role="button"
              >
                Share you QR Code
              </NavLink>
            </NavItem>
            {isMobile && (
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => toggleTab("2")}
                  role="button"
                >
                  Scan a QR Code
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <TabContent activeTab={activeTab} className="mt-3">
            <TabPane tabId="1" className="text-center">
              <UserAvatar
                name={user.current.name}
                avatarUrl={avatarUrl}
                className="mr-3"
              />
              <h5>{user.current.name}</h5>

              <div
                ref={qrCodeRef}
                className="bg-white py-4 text-center w-50 mx-auto mt-3"
              >
                <QRCodeSVG
                  value={profileUrl}
                  bgColor="#FFFFFF"
                  fgColor="#032d46"
                />
              </div>

              <p className="mt-3">
                Copy profile link:
                <a className="mx-2" onClick={onCopy} role="button">
                  {profileUrl}
                  {isLinkCopied ? (
                    <i className="mdi mdi-check mx-2" color="primary"></i>
                  ) : (
                    <i className="uil-copy-alt mx-2"></i>
                  )}
                </a>
              </p>

              <p className="mt-3">
                Save QR to photos
                <i
                  className="mdi mdi-download mx-2"
                  color="primary"
                  role="button"
                  onClick={downloadQrCode}
                ></i>
              </p>
            </TabPane>

            <TabPane
              tabId="2"
              className="text-center"
              style={{ height: "400px" }}
            >
              {isCamaraOpen && (
                <div className="d-flex justify-content-center align-content-center">
                  <QrScanner
                    delay={300}
                    onScan={handleScan}
                    style={{ width: "100%" }}
                    constraints={{
                      video: {
                        facingMode: "environment",
                      },
                    }}
                  />
                </div>
              )}
            </TabPane>
          </TabContent>
        </ModalBody>
      )}
    </Modal>
  );
};

export default React.memo(QRCodeModal);

QRCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
