import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrent, logout } from "services/usersService";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const unauthenticatedUser = {
  id: "",
  name: "",
  username: "",
  roles: ["anon"],
  isLoggedIn: false,
};

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({ ...unauthenticatedUser });

  const navigate = useNavigate();

  const getCurrentUser = async () => {
    try {
      const response = await getCurrent();
      if (response.item) {
        const isAdmin = response.item.roles.includes("Admin");
        const current = { ...response.item, isLoggedIn: true, isAdmin };
        setCurrentUser(current);
      } else {
        throw new Error("No user found");
      }
    } catch {
      if (currentUser.isLoggedIn) {
        setCurrentUser({ ...unauthenticatedUser });
      }
    }
  };
  const handleLogout = async () => {
    try {
      await logout();
      setCurrentUser({ ...unauthenticatedUser });

      toast.success("Sign out successful", {
        closeOnClick: true,
        autoClose: 500,
        hideProgressBar: true,
      });
      navigate("/");
    } catch {
      toast.error("Error signing out");
    }
  };

  const contextValue = {
    user: {
      current: currentUser,
      getCurrent: getCurrentUser,
      logout: handleLogout,
      setCurrentUser: setCurrentUser,
    },
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};
