import React, { useState } from "react";

import { Dropdown, DropdownToggle } from "reactstrap";

import withRouter from "./withRouter";
import Icon from "@ailibs/feather-react-ts";

import { withTranslation } from "react-i18next";
import { translate } from "../../constants/propTypes";

const NotificationDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon disabled"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <Icon name="bell" className="icon-sm" />
          {/* <span className="noti-dot bg-danger"></span> */}
        </DropdownToggle>
      </Dropdown>
    </React.Fragment>
  );
};

NotificationDropdown.propTypes = {
  t: translate,
};

export default withTranslation()(withRouter(NotificationDropdown));
