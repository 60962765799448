import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import withRouter from "../withRouter";
import { withTranslation } from "react-i18next";
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { Link } from "react-router-dom";
import { translate } from "../../../constants/propTypes";
import { useAppContext } from "contexts/GlobalAppContext";
import "../profilemenu.css";

const ProfileMenu = (props) => {
  // Inside your component
  // const success => "success";

  // Declare a new state variable, which we'll call "menu"
  const { user } = useAppContext();
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="position-relative">
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />
            {user.current.isVerified && (
              <div className="d-flex align-items-center justify-content-center profile-menu-verified-icon-wrapper">
                <i className="uil-check-circle m-0 p-0  profile-menu-verified-icon" />
              </div>
            )}
          </div>

          <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{user.current.handle}</span>
            <span className="user-sub-title">Administrator</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <div className="p-3 bg-primary border-bottom">
            <h6 className="mb-0 text-white">{user.current.handle}</h6>
          </div>
          {user.current.isAdmin && (
            <DropdownItem>
              <i className="mdi mdi-shield-account text-muted font-size-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                <Link to="/admin"> {props.t("Admin Page")}</Link>
              </span>
            </DropdownItem>
          )}
          <DropdownItem>
            <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              <Link to="/user/profile"> {props.t("Profile")}</Link>
            </span>
          </DropdownItem>
          <DropdownItem>
            <i className="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              {" "}
              <Link to="/chat"> {props.t("Messages")}</Link>
            </span>
          </DropdownItem>
          <DropdownItem>
            <i className="mdi mdi-lock text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              <Link to="/user/settings/security">
                {" "}
                {props.t("Security Settings")}
              </Link>
            </span>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              <Link to="/#" onClick={user.logout}>
                {props.t("Logout")}
              </Link>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  t: translate,
};

export default withTranslation()(withRouter(ProfileMenu));
