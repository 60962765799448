import React, { useEffect, useState } from "react";

import { layoutTypes } from "constants/layout";
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import AdminNavbar from "./AdminNavbar";
import { childrenProps } from "constants/propTypes";
import { useAppContext } from "contexts/GlobalAppContext";
import debug from "@services/debugService";
const _logger = debug.extend("Layout");
const disabledFx = function () {
  _logger("This function is disabled");
  return {};
};

const useSelector = disabledFx,
  dispatch = disabledFx;

const changeLayout = disabledFx,
  changeTopbarTheme = disabledFx,
  changeLayoutWidth = disabledFx,
  changelayoutMode = disabledFx;

const HorizonalLayout = (props) => {
  // const dispatch = useDispatch();

  // const selectLayoutProperties = createSelector(
  //   (state) => state.Layout,
  //   (layout) => ({
  //     layoutMode: layout.layoutMode,
  //     topbarTheme: layout.topbarTheme,
  //     layoutWidth: layout.layoutWidth,
  //     isPreloader: layout.isPreloader,
  //     layoutType: layout.layoutType,
  //     preloader: layout.preloader,
  //   })
  // );
  // Inside your component
  const { topbarTheme, layoutWidth, layoutMode, layoutType } = useSelector();

  const { user } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout(layoutTypes.HORIZONTAL));
  }, [dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [dispatch, topbarTheme]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [dispatch, layoutWidth]);

  const [isMenuOpened] = useState(false);
  // const openMenu = () => {
  //   setIsMenuOpened(!isMenuOpened);
  // };repor

  const [isAdminNav, setIsAdminNav] = useState(user.current?.isAdmin);

  useEffect(() => {
    if (layoutMode) {
      dispatch(changelayoutMode(layoutMode, layoutType));
    }
  }, [layoutMode, dispatch]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <header id="page-topbar">
          <Header />
          {isAdminNav ? (
            <AdminNavbar setIsAdminNav={setIsAdminNav} />
          ) : (
            <Navbar setIsAdminNav={setIsAdminNav} menuOpen={isMenuOpened} />
          )}
        </header>

        <div className="main-content pb-1">{props.children}</div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

HorizonalLayout.propTypes = {
  children: childrenProps,
};

export default HorizonalLayout;
