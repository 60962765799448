export const adminTools = [
  {
    groupTitle: "Users Management",
    tools: [
      { title: "Search Users", redirectTo: "/admin/users/search" },
      { title: "Example Delete User", redirectTo: "/users/delete" },
      { title: "Flagged Users", redirectTo: "/admin/users/flagged" },
      { title: "Add Users", redirectTo: "/admin/users/add" },
    ],
  },
  {
    groupTitle: "Maphias Management",
    tools: [{ title: "Some Maphia Admin Tool", redirectTo: "/admin/maphias" }],
  },
];
