import axios from "axios";
import logger from "@services/debugService";
import { captureException } from "@sentry/react";
const _xhrLogger = logger.extend("xhr");

const axiosClient = axios.create();

axiosClient.defaults.withCredentials = true;
// Add a request interceptor
axios.interceptors.request.use(function (config) {
  config.withCredentials = true;
  _xhrLogger(config);
  return config;
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status !== 404) {
      captureException(error);
      _xhrLogger(error);
    }
    return Promise.reject(error);
  }
);

/**
 * Will unpack the response body from reponse object
 * @param {*} response
 *
 */
const onGlobalSuccess = (response) => {
  /// Should not use if you need access to anything other than the data
  return response.data;
};

const onGlobalError = (err) => {
  return Promise.reject(err);
};

const API_HOST_PREFIX = process.env.REACT_APP_API_HOST_PREFIX;
const API_NODE_HOST_PREFIX = process.env.REACT_APP_API_NODE_HOST_PREFIX;
const DOMAIN = process.env.REACT_APP_DOMAIN;

logger({ API_HOST_PREFIX, API_NODE_HOST_PREFIX });

export {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
  API_NODE_HOST_PREFIX,
  DOMAIN,
  axiosClient,
};
