import React from "react";
import { Link } from "react-router-dom";
import classname from "classnames";
import Icon from "@ailibs/feather-react-ts";
import { Collapse } from "reactstrap";
import withRouter from "components/commonUI/withRouter.jsx";
import { translate } from "constants/propTypes";
import { withTranslation } from "react-i18next";
import { adminTools } from "./adminTools.js";
import PropTypes from "prop-types";

function AdminNavbar(props) {
  const handleUserModeClick = () => {
    props.setIsAdminNav(false);
  };

  const renderAdminTools = () => {
    return adminTools.map((tool, index) => {
      return (
        <li className="nav-item dropdown" key={`admin-tool-${index}`}>
          <Link className="nav-link dropdown-toggle arrow-none">
            <span>{props.t(tool.groupTitle)}</span>
            <div className="arrow-down"></div>
          </Link>
          <div className={classname("dropdown-menu")}>
            {tool?.tools?.map((subTool, index) => (
              <Link
                to={subTool.redirectTo}
                className="dropdown-item"
                key={`admin-subTool-${index}`}>
                {props.t(subTool.title)}
              </Link>
            ))}
          </div>
        </li>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation">
            <Collapse className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav me-auto">{renderAdminTools()}</ul>

              <ul className="navbar-nav ms-auto" role="button">
                <li className="nav-item">
                  <div className="nav-link p-2" onClick={handleUserModeClick}>
                    <Icon name="user" /> <span>{props.t("User Mode")}</span>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(withRouter(AdminNavbar));

AdminNavbar.propTypes = {
  t: translate,
  setIsAdminNav: PropTypes.func,
};
