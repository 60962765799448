import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { translate } from "../../../constants/propTypes";
import Icon from "@ailibs/feather-react-ts";
import { Link, useNavigate } from "react-router-dom";
import QRCodeModal from "components/commonUI/QRCodeModal";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import menuDropdown
import NotificationDropdown from "components/commonUI/NotificationDropdown";
import ProfileMenu from "components/commonUI/topbardropdown/ProfileMenu";

// import images
// import logoSm from "../../../assets/images/logo-sm.png";
// // import logoDark from "../../../assets/images/logo-dark.png";
// import logoLight from "../../../assets/images/logo-light.png";

const Header = () => {
  const [isSearch, setSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleLeftmenu = () => {
    var element = document.getElementById("topnav-menu-content");
    if (element) {
      element.classList.toggle("show");
    }
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  const toggleQrModal = () => {
    setIsQRModalOpen(!isQRModalOpen);
  };

  return (
    <React.Fragment>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-flex align-items-center">
            <Link className="" to="/home">
              <h1 className="maphia-text">Maphia</h1>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-none d-md-block d-lg-none header-item"
            onClick={() => {
              toggleLeftmenu();
            }}
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>

        <QRCodeModal isOpen={isQRModalOpen} toggle={toggleQrModal} />

        <div className="d-flex flex-grow-1 justify-content-end">
          {/* Desktop Search Input */}
          <div className="dropdown d-inline-block d-none d-md-block col-8">
            <form className="p-3" onSubmit={handleSubmit}>
              <div className="search-box">
                <div className="position-relative">
                  <i className="mdi mdi-magnify search-icon"></i>
                  <input
                    type="text"
                    className="form-control rounded bg-light border-0"
                    placeholder="Search"
                    onChange={onSearchChange}
                  />
                </div>
              </div>
            </form>
          </div>

          {/* Mobile Search Icon */}
          <div className="dropdown d-inline-block d-md-none">
            <button
              type="button"
              className="btn header-item"
              id="page-header-search-dropdown"
              onClick={() => setSearch(!isSearch)}
            >
              <Icon name="search" className="icon-sm" />
            </button>
            <div
              className={
                isSearch
                  ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                  : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-2" onSubmit={handleSubmit}>
                <div className="search-box d-flex row">
                  <div className="d-flex col-10">
                    <div className="position-relative w-100">
                      <input
                        type="text"
                        className="form-control rounded bg-light border-0"
                        placeholder="Search"
                        onChange={onSearchChange}
                      />
                      <i className="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                  <div className="d-flex col-2 mx-auto">
                    <i
                      className="mdi mdi-qrcode-plus text-muted font-size-20 align-middle me-3"
                      role="button"
                      onClick={toggleQrModal}
                    ></i>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="row justify-content-center align-items-center mx-2 d-md-none ">
            {/* Create reacstrap uncontrolled dropdown with Icon plus square and 2 options to Add Maphia and Add Inner Circle */}
            <UncontrolledDropdown className="p-0">
              <DropdownToggle
                className="nav-link dropdown-toggle arrow-none"
                tag="a"
              >
                <Icon name="plus-square" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} to="/maphias/new">
                  Add Maphia
                </DropdownItem>
                <DropdownItem tag={Link} to="/inner-circles/new">
                  Add Inner Circle
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <NotificationDropdown />
          <div className="row justify-content-center align-items-center mx-2 d-md-none ">
            <Link className="nav-link dropdown-toggle arrow-none" to="/chat">
              <Icon name="message-square" />{" "}
            </Link>
          </div>
          <ProfileMenu />
        </div>
      </div>
    </React.Fragment>
  );
};
Header.propTypes = {
  t: translate,
};

export default withTranslation()(Header);
