import React from "react";
import PropTypes from "prop-types";

const UserAvatar = ({ name, avatarUrl, fontSize = 20 }) => {
  const size = fontSize < 16 ? "sm" : fontSize < 24 ? "md" : "lg";

  return (
    <div className="avatar-group justify-content-center align-items-center">
      <div className="avatar-group-item">
        {avatarUrl ? (
          <>
            {" "}
            <img
              className={`avatar-${size} rounded-circle`}
              src={avatarUrl}
              alt={`${name} profile avatar`}
            />
          </>
        ) : (
          <div
            className={`avatar-${size} d-flex justify-content-center align-items-center rounded-circle bg-info text-white flex-shrink-0`}
          >
            <p
              className={`avatar-title rounded-circle bg-info text-white font-size-${fontSize} w-100 h-100 m-0`}
            >
              {name.charAt(0)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  fontSize: PropTypes.number,
};

export default UserAvatar;
